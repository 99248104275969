import React from 'react'
import { graphql } from 'gatsby'
import LocatableDetail from "../components/locatable-detail"

export default ({ data }) => (
  <LocatableDetail settings={data.datoCmsWebsiteLayout} locatable={data.locatable} />
)

export const query = graphql`
  query ServiceQuery($id: String!) {
    datoCmsWebsiteLayout {
      sponsors {
        ...Sponsor
      }
    }
    locatable: datoCmsService(id: { eq: $id }) {
      title
      subtitle
      summary
      description
      itemSponsors {
          ...Sponsor
      }  
      heroImage {
        url
        fluid(maxWidth: 2800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      subcategories {
        title
        heroTitleBlock {
          ...HeroTitleBlock
        }
        sponsors {
          ...Sponsor
        }    
        landingPageDiscoverMore {
          ...DiscoverMore
        }
      }
      locations {
        title
        building {
          id
          title
          slug
        }
      }
      model {
        apiKey
      }
    }
  }
`
